<template>
  <v-card v-on:scroll="onScroll">
    <v-card-title>
      <p>Gerador de requisições</p>
      <v-spacer></v-spacer>
      <v-checkbox
        @change="axiosDebug"
        v-model="$store.state.getDebugAxiosRequest"
        label="Mostrar dados do request no console?"
      ></v-checkbox>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-select
            :items="['get', 'post', 'put', 'delete']"
            v-model="method"
            label="Selecione o Metodo"
            outlined
            presistent-hint
          >
          </v-select>
        </v-col>
        <v-col>
          <v-autocomplete
            v-if="routes.length"
            v-model="url"
            label="URL"
            hint="URL do recurso"
            :items="routes"
            outlined
            presistent-hint
          >
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="url"
            :counter="50"
            label="URL"
            hint="URL do recurso"
            required
            outlined
            presistent-hint
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="complement"
            :counter="50"
            label="Complemento da url"
            hint="Complemento da URL"
            required
            outlined
            presistent-hint
          ></v-text-field>
        </v-col>
      </v-row>
      <p for="">URL que sera usada: {{ url + complement }}</p>
      <v-btn @click="addParameter">Adicionar Parametro</v-btn>
      <v-row>
        <v-col>
          <div v-for="(item, index) in parameters" :key="index">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="parameters[index].key"
                  hint="Nome do Atributo"
                  presistent-hint
                  @input="checkValue(index)"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="parameters[index].value"
                  hint="Valor do Atributo"
                  presistent-hint
                  @input="checkValue(index)"
                  @keypress.enter="addParameter"
                  :type="parameters[index].number ? 'number' : 'text'"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  v-model="parameters[index].number"
                  label="Numero?"
                ></v-checkbox>
                <v-checkbox
                  v-model="parameters[index].json"
                  label="json?"
                ></v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-icon @click="del(index)">mdi-delete</v-icon>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            v-model="dataText"
            rows="3"
            label="DATA"
            hint="Json se sera enviado"
            :color="validParameters ? 'green' : 'red'"
            @input="jsonValidate"
            :counter="255"
            outlined
            presistent-hint
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row align-content="center">
        <v-spacer></v-spacer>
        <v-col>
          <v-btn color="warning" @click="clearData">Limpar</v-btn>
        </v-col>
        <v-col>
          <v-btn color="success" @click="addHistoty">Salvar Requisição</v-btn>
        </v-col>
        <v-col>
          <v-checkbox
            label="Mostrar Console"
            v-model="showConsole"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-btn color="primary" @click="request">Enviar</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card v-scroll.self="onScroll">
            <v-card-title>
              Resposta {{ responseStatus }}
              {{ reponseTime ? `Tempo de resposta: ${reponseTime} ms` : "" }}
            </v-card-title>
            <v-card-text>
              <v-textarea
                v-show="response.length > 1"
                v-model="response"
                auto-grow
                label="DATA"
                hint="Json se sera enviado"
                outlined
                presistent-hint
              ></v-textarea>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-simple-table height="500px">
        <thead>
          <tr>
            <th class="text-left">URL</th>
            <th class="text-left">Complemento</th>
            <th class="text-left">DADOS</th>
            <th class="text-left">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in reqHistory" :key="index">
            <td>{{ item.url }}</td>
            <td>{{ item.complement }}</td>
            <td>{{ JSON.stringify(item.data) }}</td>
            <td>
              <v-icon @click="removeHistory(index)" class="m-1">
                mdi-trash-can
              </v-icon>
              <v-icon @click="setHistory(index)"> mdi-play </v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      url: "",
      complement: "",
      method: "get",
      data: "",
      dataText: "",
      response: "",
      responseStatus: "",
      reponseTime: 0,
      validParameters: false,
      routes: [],
      posY: 0,
      parameters: [],
      temp: {},
      reqHistory: [],
      showConsole: false,
    };
  },
  methods: {
    request() {
      if (this.showConsole) {
        console.log(this.data);
      }
      this.response = "";
      this.responseStatus = "";
      let time = new Date().getTime();
      this.$http[this.method](
        `/${this.url.substring(5)}${this.complement}`,
        this.getJson()
      ).then((resp) => {
        if (this.showConsole) {
          console.log(resp.data);
        }
        this.reponseTime = new Date().getTime() - time;
        try {
          this.response = JSON.stringify(resp.data, null, 2);
        } catch (error) {
          this.response = resp.data;
        }
        this.responseStatus = resp.status;
      });
    },
    getRecAvailable() {
      this.$http("/rotas").then((resp) => {
        this.routes = resp.data;
      });
    },
    addParameter() {
      this.parameters.push({ value: "", key: "" });
    },
    checkValue() {
      this.parameters.forEach((e, idx) => {
        if (this.parameters[idx].key && this.parameters[idx].value) {
          this.temp[this.parameters[idx].key] = this.parameters[idx].number
            ? parseFloat(this.parameters[idx].value)
            : this.parameters[idx].value;
          this.temp[this.parameters[idx].key] = this.parameters[idx].json
            ? JSON.parse(this.parameters[idx].value)
            : this.parameters[idx].value;
        }
      });
      this.jsonValidate(this.temp);
      this.data = this.temp;
      this.dataText = JSON.stringify(this.temp);
    },
    del(idx) {
      this.parameters.splice(idx, 1);
      this.checkValue();
      this.clearData();
      this.checkValue();
    },
    clearData() {
      this.dataText = "";
      this.json = "";
      this.data = "";
      this.temp = {};
      this.response = "";
    },
    jsonValidate(data) {
      try {
        JSON.parse(data);
        this.validParameters = true;
        this.json = data;
      } catch (error) {
        console.log(error);
        // this.data = null;
        this.validParameters = false;
      }
    },
    getJson() {
      try {
        return JSON.parse(this.dataText);
      } catch (error) {
        return {};
      }
    },
    onScroll() {
      this.posY = window.scrollY;
      console.log(this.posY);
    },
    addHistoty() {
      let js = localStorage.getItem("requests");
      if (js) {
        let json = JSON.parse(js);
        json.push({
          url: this.url,
          complement: this.complement,
          method: this.method,
          data: this.data,
        });
        localStorage.setItem("requests", JSON.stringify(json));
      } else {
        let json = [];
        json.push({
          url: this.url,
          complement: this.complement,
          method: this.method,
          data: this.data,
        });
        localStorage.setItem("requests", JSON.stringify(json));
      }
      this.getHistory();
    },
    getHistory() {
      let js = localStorage.getItem("requests");
      if (js) {
        this.reqHistory = JSON.parse(js);
      }
    },
    removeHistory(item) {
      let js = localStorage.getItem("requests");
      if (js) {
        this.reqHistory = JSON.parse(js);
        this.reqHistory.splice(item, 1);
        localStorage.setItem("requests", JSON.stringify(this.reqHistory));
        this.getHistory();
      }
    },
    setHistory(index) {
      let js = localStorage.getItem("requests");
      if (js) {
        this.reqHistory = JSON.parse(js);
        let a = this.reqHistory[index];
        this.dataText = JSON.stringify(a.data);
        this.json = a.data;
        this.data = a.data;
        this.temp = {};
        this.url = a.url;
        this.complement = a.complement;
        this.response = "";
      }
    },
    axiosDebug(sts) {
      this.$store.dispatch("setdebugAxiosRequest", sts);
    },
  },
  created() {
    this.getRecAvailable();
    this.getHistory();
  },
};
</script>

<style>
.botaoSubir {
  position: absolute !important;
  margin-right: 0%;
}
</style>