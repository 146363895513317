<template>
  <v-card>
    <v-card-title> NodeAdmin </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="3">
          <v-select
            :items="['get', 'post', 'put', 'delete']"
            v-model="method"
            label="Selecione o Metodo"
            outlined
            presistent-hint
          >
          </v-select>
        </v-col>
        <v-col>
          <v-autocomplete
            v-if="routes.length"
            v-model="url"
            label="URL"
            hint="URL do recurso"
            :items="routes"
            outlined
            presistent-hint
          >
          </v-autocomplete>
          <v-text-field
            v-else
            v-model="url"
            :counter="50"
            label="URL"
            hint="URL do recurso"
            required
            outlined
            presistent-hint
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="complement"
            :counter="50"
            label="Complemento da url"
            hint="Complemento da URL"
            required
            outlined
            presistent-hint
          ></v-text-field>
        </v-col>
        <v-col cols="1" class="mt-2">
          <v-btn color="primary" @click="get" :disabled="url.length < 1"
            >Enviar</v-btn
          >
        </v-col>
      </v-row>
      <p for="">URL que sera usada: {{ url + complement }}</p>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-left" v-for="(item, index) in header" :key="index">
              {{ item }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in response" :key="index">
            <td v-for="(itemH, indexH) in header" :key="indexH">
              <p @dblclick="edtValue(index, itemH)">{{ item[itemH] }}</p>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="dialogEDT" width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Editar Atributo {{ toEdit.name }} do id {{ toEdit.id }}
        </v-card-title>

        <v-card-text>
          <v-row class="mt-3">
            <v-col>
              <v-text-field
                v-model="toEdit.value"
                :hint="toEdit.name"
                persistent-hint
                outlined
                :type="typeof toEdit.value == 'number' ? 'number' : 'text'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialogEDT = false">
            Cancelar
          </v-btn>
          <v-btn color="warning" text @click="sendEdt(toEdit)"> Editar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  data: () => {
    return {
      url: "",
      complement: "",
      method: "get",
      data: "",
      dataText: "",
      response: [],
      responseStatus: "",
      reponseTime: 0,
      validParameters: false,
      routes: [],
      posY: 0,
      parameters: [],
      temp: {},
      reqHistory: [],
      header: [],
      toEdit: {},
      dialogEDT: false,
    };
  },
  methods: {
    get() {
      this.response = "";
      this.responseStatus = "";
      let url = `/${this.url.substring(5)}${this.complement}`;
      let time = new Date().getTime();
      this.$http(url).then((resp) => {
        this.reponseTime = new Date().getTime() - time;
        if (resp.data.length > 0) {
          let model = resp.data[0];
          for (const key in model) {
            if (Object.hasOwnProperty.call(model, key)) {
              this.header.push(key);
            }
          }
          this.response = resp.data;
        }
        this.responseStatus = resp.status;
      });
    },
    edtValue(index, item) {
      let att = this.response[index];
      this.dialogEDT = true;
      this.toEdit = { id: att.id, name: item, value: att[item], index };
    },
    sendEdt(toEdit) {
      let att = this.response[toEdit.index];
      att[toEdit.name] = toEdit.value;
      let url = `/${this.url.substring(5)}/${att.id}`;
      this.$http.put(url, att).then((resp) => {
        if (resp.status < 300) {
          this.$store.dispatch("resetSnack");
          this.$store.dispatch("showSuccessSnack", `Atributo ${toEdit.name} editado para ${toEdit.value} com sucesso`);
          this.get()
          this.dialogEDT = false
          this.toEdit = {}
        }
      });
    },
    getRecAvailable() {
      this.$http("/rotas").then((resp) => {
        this.routes = resp.data;
      });
    },
    clearData() {
      this.dataText = "";
      this.json = "";
      this.data = "";
      this.temp = {};
      this.response = "";
    },
  },
  created() {
    this.getRecAvailable();
  },
};
</script>

<style>
.botaoSubir {
  position: absolute !important;
  margin-right: 0%;
}
</style>