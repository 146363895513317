<template>
  <v-container class="d-flex flex-wrap justify-center">
    <v-card
      v-for="(icon, index) in icons"
      :key="index"
      class="ma-1"
      @click="copy(icon)"
    >
      <v-card-text class="d-flex justify-center">
        <v-icon class="">{{ icon }}</v-icon>
      </v-card-text>
      <v-card-actions>
        {{ icon }}
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
const i = require("./icons.json");
export default {
  name: "icons",
  data: () => {
    return {
      icon: "",
      icons: i,
    };
  },
  methods: {
    copy(i) {
      console.log(i);
      navigator.clipboard.writeText(i);
    },
  },
};
</script>

<style>
</style>