var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"show-select":_vm.enableSelec,"single-select":_vm.enableSelec,"options":{ itemsPerPage: _vm.rows },"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletar(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.get",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.get ? "S" : "N"))])]}},{key:"item.getParam",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.getParam ? "S" : "N"))])]}},{key:"item.getQuery",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.getQuery ? "S" : "N"))])]}},{key:"item.post",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.post ? "S" : "N"))])]}},{key:"item.postParam",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.postParam ? "S" : "N"))])]}},{key:"item.postQuery",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.postQuery ? "S" : "N"))])]}},{key:"item.put",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.put ? "S" : "N"))])]}},{key:"item.putParam",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.putParam ? "S" : "N"))])]}},{key:"item.putQuery",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.putQuery ? "S" : "N"))])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.delete ? "S" : "N"))])]}},{key:"item.deleteParam",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.deleteParam ? "S" : "N"))])]}},{key:"item.deleteQuery",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.deleteQuery ? "S" : "N"))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }