<template>
  <div class="pendentes">
    <div class="form">
      <v-card class="mx-auto" elevation="5" outlined>
        <v-card-title>
          Equipamentos 
          <v-spacer></v-spacer>
          <v-btn color="error" href="http://dobil.stv.com.br/app.apk" >Baixar Aplicativo</v-btn>
          <v-spacer></v-spacer>
          <v-checkbox v-model="dispositivo.enabled" label="Ativo"></v-checkbox>
        </v-card-title>
        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="dispositivo.name"
                  :counter="50"
                  label="Nome do Dispositivo"
                  hint="Digite algum nome para identificar este Dispositivo"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
               
              <v-autocomplete
                v-model="dispositivo.groupId"
                :items="getGroupSelect"
                label="Selecione o Grupo"
              ></v-autocomplete>
              </v-col>  
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="dispositivo.identificador"
                  :counter="50"
                  label="Identificador do Dispositivo"
                  hint="Digite o identificador do Dispositivo"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="dispositivo.phone"
                  :counter="50"
                  label="Numero do Dispositivo"
                  hint="Digite o numero de telefone do Dispositivo"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="dispositivo.description"
                  rows="1"
                  label="Descrição"
                  hint="Digite uma descrição do Dispositivo"
                  :counter="255"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed color="warning" class="mr-2" @click="clear">
                Cancelar
              </v-btn>
              <v-btn depressed color="success" @click="save">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-form>
      </v-card>
      <v-card class="mt-1">
        <v-card-title>Dispositivos Cadastrados</v-card-title>
        <v-card-text>

          <Table
            :headers="header"
            :items="dispositivos"
            @edit="edit($event)"
            :rows="15"
            @delete="delet($event.id)"
          />
        </v-card-text>
      </v-card>
      <v-row>
        <v-col>
        </v-col>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters} from 'vuex'
import Table from "@/components/TableGeneric";
export default {
  name: "AguardandoLiberacao",
  components: {
    Table
  },
  data: () => {
    return {
      text: "",
      snackbar: false,
      dispositivo: {
        id: null,
        enabled: true,
      },
      dispositivos: [],
      header: [
        { text: "ID", value: "id" },
        { text: "Nome", value: "name" },
        { text: "Identificador", value: "identificador" },
        { text: "Numero", value: "phone" },
        { text: "Descrição", value: "description" },
        { text: "Grupo", value: "groupName" },
        { text: "Primeiro Cadastro", value: "createdAt" },
        { text: "Editado", value: "updatedAt" },
        { text: "Ultimo Login", value: "lastLogin" },
        { text: "Ativo", value: "ativo" },
        { text: "Ações", value: "actions" },
      ],
    };
  },
  computed:{
      ...mapGetters(['getGroupSelect'])
  },
  methods: {
    get() {
      this.$http("dispositivo").then((resp) => {
        resp.data.forEach((element) => {
          element.ativo = element.enabled ? "Sim" : "Não";
          element.groupName = this.$store.getters.getGroupNameById(element.groupId) 
          element.createdAt = new Date(element.createdAt).toLocaleString();
          element.updatedAt = new Date(element.updatedAt).toLocaleString();
          element.lastLogin = new Date(element.lastLogin).toLocaleString();
        });
        this.dispositivos = resp.data;
      });
    },
    save() {
      delete this.dispositivo.createdAt
      delete this.dispositivo.updatedAt
      const metodo = this.dispositivo.id ? "put" : "post";
      const finalUrl = this.dispositivo.id ? `/${this.dispositivo.id}` : "";
      this.$http[metodo](`/dispositivo${finalUrl}`, this.dispositivo).then(() => {
        this.get();
        this.clear();
        (this.snackbar = true), (this.text = "Salvo com Sucesso");
      });
    },
    edit(rec) {
      this.dispositivo = rec;
      this.$vuetify.goTo(0, { easing: "easeInOutCubic", duration: 300 });
    },
    delet(id) {
      this.$http.delete(`/dispositivo/${id}`).then(() => {
        this.get();
        (this.snackbar = true), (this.text = "Deletado com Sucesso");
      });
    },
    groupName(id){
      if(!id){
        return "Sem Grupo"
      }
      let gp = this.$store.state.grupos
      return gp.find(g =>
        g.id == id
      ).name
    },
    clear() {
      this.dispositivo = {
        id: null,
        enabled: true,
      };
    },
  },
  mounted() {
    this.get();
    this.$store.dispatch('loadGrupos')
  },
};
</script>

<style>
</style>