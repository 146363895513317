var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pesquisa","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"show-select":_vm.enableSelec,"single-select":_vm.enableSingleSelec,"options":{ itemsPerPage: _vm.rows },"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([(_vm.enablePicture)?{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.picture,"width":"100"}})]}}:null,(_vm.enablePicture)?{key:"item.picture2",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.picture2,"width":"100"}})]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editar(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deletar(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.$emit('play', item)}}},[_vm._v("mdi-google-play")])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }